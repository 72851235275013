<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">{{ isEdit ? '编辑' : '新增' }}用户</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="登陆名称">
          <a-input v-model:value.trim="username" placeholder="请输入用户名称" allowClear></a-input>
        </a-form-item>
        <a-form-item label="用户昵称">
          <a-input v-model:value.trim="nickName" placeholder="请输入用户昵称" allowClear></a-input>
        </a-form-item>
        <a-form-item label="用户性别">
          <a-select v-model:value="sex" placeholder="请选择性别">
            <a-select-option :value="0">男</a-select-option>
            <a-select-option :value="1">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="用户手机号">
          <a-input-number v-model:value.trim="telephone"  placeholder="请输入用户手机号" allowClear />
        </a-form-item>
        <a-form-item label="用户头像">
          <a-upload-dragger
            class="cover-file"
            :file-list="fileList"
            :remove="removeFile"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon" v-show="!avatarBase64">
              <plus-outlined></plus-outlined>
            </p>
            <img :src="avatarBase64" v-show="avatarBase64" class="cover-img" />
          </a-upload-dragger>
        </a-form-item>
        <a-form-item label="用户组织">
          <!-- <a-select v-model:value="departmentId" placeholder="请选择用户组织" allowClear>
            <a-select-option v-for="item in organizationList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select> -->
            <a-tree-select
              show-search
              v-model:value="departmentId"
              placeholder="输入需要搜索的资源名称或街道等"
              allow-clear
              :tree-data="organizationList"
              @select="treeSelect"
              treeNodeFilterProp="title"
            >
            </a-tree-select>
        </a-form-item>
        <a-form-item label="用户角色">
          <a-select v-model:value="roleId" placeholder="请选择用户角色" allowClear>
            <a-select-option v-for="item in roleList" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, toRefs, nextTick } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';
import api from '@/services';
import { message } from 'ant-design-vue';

export default defineComponent({
  components: {
    PlusOutlined
  },
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const isEdit = ref(false);
    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      nickName: '',
      username: '',
      sex: 0,
      telephone: '',
      departmentId: '',
      roleId: '',
      id: '', // 编辑时选中用户的 id
      avatarBase64: '', // 上传头像 base64
      organizationList: [], // 组织下拉选项
      roleList: [] // 角色下拉选项
    });
    const fileList = ref([]); // 上传图片列表

    const showModal = (flag, rowData) => {
      visible.value = true;
      getOrganizationOptions();
      getRoleOptions();
      nextTick(() => {
        isEdit.value = flag;
        if(rowData) {
          Object.keys(formState).map(key => {
            formState[key] = rowData[key];
          });
        }
        // formState.avatarBase64 = rowData.avatar;
        console.log('rowData===', rowData);
        if (rowData) {
          getUserInfoById(rowData.id);
        }
        console.log('formState====', formState);
      });
    };

    // 根据用户 ID 获取用户信息（base64的头像）
    const getUserInfoById = async id => {
      const { data, success } = await api.getUserInfoById(id);
      if (success) {
        formState.avatarBase64 = 'data:image/jpg;base64,' + data.avatarBase64;
      }
    };

    function t(arr) {
      for (var i = 0; i < arr?.length; i++) {
        if (arr[i].children) {
          arr[i].children = t(arr[i].children);
        }
        arr[i].resourceName && (arr[i].title = arr[i].resourceName);
        arr[i].resourceType && (arr[i].value = arr[i].resourceType);
        arr[i].resourcesId && (arr[i].value = arr[i].resourcesId);
        delete arr[i].resourceName;
        delete arr[i].resourceType;
        delete arr[i].resourceTypeId;
        delete arr[i].parentType;
        delete arr[i].id;
        delete arr[i].parentId;
        delete arr[i].playUrl;
        delete arr[i].resourcesId;
      }
      return arr || [];
    }
    const getOrganizationOptions = async () => {
      const { data, success } = await api.getOrganizationOptions();
      if (success) {
        formState.organizationList = t(data)
      }
    };

    // 获取用户角色下拉选项
    const getRoleOptions = async () => {
      const { data, success } = await api.getRoleOptions();
      if (success) {
        formState.roleList = data;
      }
    };

    // base64转换
    const selfUpload = ({ file }) => {
      new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
          formState.avatarBase64 = fileReader.result;
        };
      });
    };
    // 修改上传状态 和 限制上传数量
    const handleChange = info => {
      const isLt2M = info.file.size / 1024 / 1024 < 0.2;
      if (!isLt2M) {
        message.error('Image must smaller than 0.2MB!');
        return;
      }
      let resFileList = [...info.fileList];
      resFileList = resFileList.slice(-1);
      fileList.value = resFileList;
      if (fileList.value.length) fileList.value[0].status = 'done';
    };

    // 删除
    const removeFile = () => {
      formState.avatarBase64 = '';
    };

    const treeSelect = (value, node, extra) => {
      formState.departmentId = node.vcTreeNode.title;
    };

    const handleOk = async () => {
      loading.value = true;
      const functionName = isEdit.value ? 'updateUser' : 'addUser';
    
      if(functionName == 'addUser') {
        Object.keys(formState).map(key => {
          if(key == 'sex' || key == 'id') {

          } else if(!formState[key]) {
            loading.value = false
          }
        });
      }

      if(!loading.value) {
        message.error('请输入完整')
        return false 
      }

      let params = {
        ...formState
      };
      delete params.organizationList;
      delete params.roleList;
      const { success } = await api[functionName](params);
      if (success) {
        message.success(`${isEdit.value ? '编辑' : '新增'}成功！`);
        context.emit('addOrUpdateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      fileList.value = '';
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };



    return {
      isEdit,
      labelCol,
      wrapperCol,
      getUserInfoById,
      ...toRefs(formState),
      loading,
      visible,
      showModal,
      fileList,
      getOrganizationOptions,
      getRoleOptions,
      selfUpload,
      handleChange,
      removeFile,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm,
      treeSelect
    };
  }
});
</script>

<style lang="less" scoped>
::v-deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .ant-form {
    color: blue;
    .ant-form-item {
      .ant-upload-drag-icon {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :deep(.anticon-plus) {
          color: #d4d4d4;
          font-size: 24px;
        }
      }
      .cover-img {
        height: 148px;
        border-radius: 10px;
      }
    }
  }
}
.ant-input-number {
  width: 100%;
  ::v-deep .ant-input-number-handler-wrap {
    display: none;
  }
}

</style>
